import acerola from './acerola';
import advertisea from './advertisea';
import blackLilac from './blackLilac';
import geranium from './geranium';
import geraniumLeft from './geraniumLeft';
import ginger from './ginger';
import icelandLichen from './icelandLichen';
import vitaminD from './vitaminD';
import vitaminDLeft from './vitaminDLeft';
import zinc from './zinc';

export const backgrounds = {
  acerola,
  advertisea,
  blackLilac,
  geranium,
  geraniumLeft,
  ginger,
  icelandLichen,
  vitaminD,
  vitaminDLeft,
  zinc,
};
