import * as React from 'react';

const SvgVitaminDLeft = props => (
  <svg
    data-name="Component 10 \u2013 1"
    height={740.722}
    id="Component_10_1"
    viewBox="0 0 740.662 740.722"
    width={740.662}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      data-name="Group 1978"
      id="Group_1978"
    >
      <g
        data-name="Group 1976"
        id="Group_1976"
      >
        <path
          d="M364.244,174.125c106.994.574,191.671,85.537,192.615,190.756.972,108.3-86.061,195.058-194.337,194.412-103.2-.615-194.65-85.85-190.572-200.418,3.534-99.286,85.132-184.129,192.294-184.749M290.8,479.3c13.026.512,25.748,1.013,38.47,1.512,1.315.052,2.634.168,3.946.118,10.31-.388,20.664-.341,30.914-1.353A137.112,137.112,0,0,0,393.739,473C418.3,464.865,436.534,449.53,448,426.274c8.025-16.281,11.113-33.707,11.568-51.662a126.916,126.916,0,0,0-3.118-32.188c-4.481-19.2-14.068-35.346-29.187-48-9.853-8.244-21.34-13.565-33.68-16.982a124.7,124.7,0,0,0-28.161-4.786c-8.571-.352-17.156-.989-25.718-.8-10.3.232-20.6,1.092-30.881,1.9-5.942.469-11.849,1.377-18.017,2.117Z"
          data-name="Path 4108"
          fill="#fff"
          id="Path_4108"
          transform="translate(9.363 9.489)"
        />
        <path
          d="M378.135,41.438c.308,13.06.882,26.121.853,39.18q-.049,22.75-1.015,45.5c-.364,8.409-1.607,16.783-2.588,25.158a36.654,36.654,0,0,1-1.513,5.224c-1.475,5.36-6.979,7.278-11.869,5.065-7.414-3.355-11.56-9.462-13.971-16.943-2.723-8.448-3.644-17.146-3.763-26-.22-16.309-.854-32.615-.914-48.924-.04-10.776.4-21.567,1.038-32.326.463-7.786,1.7-15.572,4.8-22.782,2.648-6.161,6.578-11.671,13.329-13.832C367.9-.966,372.11.1,373.612,5.567a111.767,111.767,0,0,1,3.311,15.96c.8,6.591.926,13.264,1.342,19.9l-.131.011"
          data-name="Path 4109"
          fill="#fff"
          id="Path_4109"
          transform="translate(18.71 0)"
        />
        <path
          d="M325.725,599.814c.388-9.541.5-19.105,1.272-28.616A82.6,82.6,0,0,1,330.06,555.7c2.151-7.666,9.089-9.468,15.573-4.649,7.332,5.449,10.955,13,12.227,21.624A202.824,202.824,0,0,1,360,597.112c.45,17.974.576,35.959.554,53.941a250.179,250.179,0,0,1-1.145,26.232,75.5,75.5,0,0,1-4.262,18.606c-2.49,6.567-6.814,12.193-14.071,14.45-5.15,1.6-9.462-.735-10.964-5.86a90.248,90.248,0,0,1-3.3-20.252c-1.4-23.917-1.664-47.85-1.494-71.793.031-4.205.4-8.408.617-12.612l-.206-.009"
          data-name="Path 4110"
          fill="#fff"
          id="Path_4110"
          transform="translate(17.725 29.884)"
        />
        <path
          d="M216.575,212.027c-7.456.2-13.848-2.789-19.661-6.873a141.349,141.349,0,0,1-15.7-12.541c-12.386-11.658-24.719-23.4-36.592-35.571-9.33-9.568-18.158-19.655-26.769-29.882a35.938,35.938,0,0,1-8.107-15.857c-.926-4.551-1.411-9.107.257-13.643,1.265-3.441,3.6-6.107,7.069-6.663,2.13-.342,4.71.792,6.807,1.831a65.356,65.356,0,0,1,16.188,11.781c16.358,15.743,32.975,31.223,49.089,47.21,10.374,10.293,20.239,21.124,29.923,32.075a73.793,73.793,0,0,1,9.587,14.482c3.035,5.745-.241,11.58-6.678,12.923-1.78.371-3.611.495-5.418.731"
          data-name="Path 4111"
          fill="#fff"
          id="Path_4111"
          transform="translate(5.935 4.955)"
        />
        <path
          d="M599.575,603.9c.178,4.933-.757,9.4-5.036,12.512a6.49,6.49,0,0,1-6.785.914A52.861,52.861,0,0,1,571.423,606.7c-18.2-17.255-36.423-34.484-54.19-52.173-10.6-10.552-20.5-21.815-30.415-33.031-2.885-3.264-4.824-7.4-7-11.244-3.147-5.557.2-9.561,4.057-11.718,4.128-2.309,8.661-1.935,13.04-1.3,9.99,1.449,17.824,7.334,24.932,13.8,13.022,11.847,25.682,24.106,38.171,36.518,8.945,8.891,17.624,18.081,25.932,27.568,5.877,6.711,11.131,14.044,13.14,23.057a41.4,41.4,0,0,1,.486,5.73"
          data-name="Path 4112"
          fill="#fff"
          id="Path_4112"
          transform="translate(26.079 27.07)"
        />
        <path
          d="M596.365,343.543c16.339,0,31.083-.133,45.825.043,11.134.133,22.275.451,33.391,1.087a62.456,62.456,0,0,1,21.448,4.853,26.52,26.52,0,0,1,12.314,10.9,10.314,10.314,0,0,1,.268,10.035,7.057,7.057,0,0,1-4.23,3.084c-5.608,1.454-11.274,3.233-17,3.6-26.444,1.678-52.936,2.109-79.428,1.662q-19.191-.324-38.359-1.655c-5.137-.353-10.194-1.943-15.276-3.016-8.138-1.717-8.4-9.338-4.827-14.655a29.267,29.267,0,0,1,20.2-12.681c9-1.5,18.116-2.324,25.673-3.256"
          data-name="Path 4113"
          fill="#fff"
          id="Path_4113"
          transform="translate(29.879 18.718)"
        />
        <path
          d="M496.575,216.508c.1-7.959,3.232-14.811,7.816-20.981a166.529,166.529,0,0,1,12.18-14.714c10.029-10.678,20.154-21.277,30.548-31.6,9.27-9.2,18.756-18.215,28.525-26.884,6.011-5.335,12.6-10.33,20.63-12.133,4.379-.984,9.142-.755,13.67-.36,1.99.175,4.106,1.837,5.668,3.341,3.068,2.952,2.941,6.581.71,9.944-3.548,5.347-6.838,10.992-11.132,15.694q-19.152,20.971-39.06,41.248c-8.822,9.022-17.978,17.738-27.307,26.236-7.483,6.817-15.419,13.139-23.231,19.587a23.205,23.205,0,0,1-4.74,2.7c-6.071,2.933-11.772.239-13.462-6.411a51.752,51.752,0,0,1-.816-5.663"
          data-name="Path 4114"
          fill="#fff"
          id="Path_4114"
          transform="translate(27.06 5.967)"
        />
        <path
          d="M44.646,325.55c17.2,0,34.39-.056,51.586.023,9.035.041,18.09-.011,27.1.581,9.8.643,19.731.279,29.312,3.07,1.068.311,2.121.669,3.173,1.029,6.518,2.236,8.54,8.4,4.706,14.236-4.548,6.924-11.159,10.733-19.019,12.672a98.347,98.347,0,0,1-22.753,2.725c-17.187.166-34.37.826-51.556.9-10.073.045-20.158-.441-30.215-1.056-7.8-.477-15.623-1.585-22.8-4.867C7.979,352.033,2.7,347.926.406,341.035A8.4,8.4,0,0,1,5.137,330.75,54.629,54.629,0,0,1,23.4,327.1c3.586-.124,7.17-.332,10.752-.543,3.494-.2,6.985-.462,10.475-.695q.006-.158.016-.316"
          data-name="Path 4115"
          fill="#fff"
          id="Path_4115"
          transform="translate(0 17.739)"
        />
        <path
          d="M211.663,493.429c.635,6.678-2.6,13.018-6.784,18.726a214.043,214.043,0,0,1-15.475,18.681c-9.032,9.756-18.249,19.358-27.74,28.667q-14.6,14.323-29.924,27.893c-5.786,5.1-12.257,9.655-20,11.267a35.481,35.481,0,0,1-12.633.329c-7.185-1.162-9.875-7.234-6.671-13.748,3.314-6.735,8.029-12.493,13.16-17.852,15.934-16.641,31.839-33.318,48.112-49.627,9.831-9.854,20.108-19.3,30.617-28.428,4.58-3.979,10.093-6.986,15.467-9.908,3.481-1.893,6.815-.433,9.251,2.779,2.362,3.116,2.767,6.672,2.616,11.22"
          data-name="Path 4116"
          fill="#fff"
          id="Path_4116"
          transform="translate(4.965 26.08)"
        />
        <path
          d="M309.3,370.915q0-39.917-.021-79.833c0-1.411.151-2.259,1.884-2.439,5.622-.583,11.208-1.549,16.837-1.99a132.021,132.021,0,0,1,16.805-.523c15.251.761,30.182,3.069,43.915,10.431,17.917,9.605,28.45,24.723,33.364,44.135,2.84,11.22,3.169,22.674,2.54,34.093-.878,15.966-4.5,31.328-13.293,44.966a68.9,68.9,0,0,1-31.593,26.494,95.932,95.932,0,0,1-26.77,6.922,165.645,165.645,0,0,1-31.712.568c-3.047-.233-6.1-.51-9.151-.582-2.066-.047-2.864-.68-2.858-2.934.079-26.436.051-52.872.051-79.309"
          data-name="Path 4117"
          fill="#fff"
          id="Path_4117"
          transform="translate(16.852 15.587)"
        />
      </g>
    </g>
  </svg>
);

export default SvgVitaminDLeft;

