import * as React from 'react';

const SvgPlay = props => (
  <svg
    height={168.923}
    viewBox="0 0 240.029 168.923"
    width={240.029}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M230.674 14.501c-8.664-10.3-24.661-14.5-55.212-14.5h-110.9c-31.249 0-47.517 4.472-56.149 15.438C0 26.129 0 41.883 0 63.683v41.555c0 42.238 9.985 63.684 64.565 63.684h110.9c26.493 0 41.174-3.707 50.671-12.8 9.74-9.321 13.9-24.54 13.9-50.887V63.683c-.007-22.991-.658-38.837-9.362-49.182Zm-76.574 75.7-50.358 26.319a7.743 7.743 0 0 1-11.329-6.863V57.183a7.743 7.743 0 0 1 11.312-6.871l50.358 26.149a7.743 7.743 0 0 1 .018 13.734Z"
      data-name="Path 47"
    />
  </svg>
);

export default SvgPlay;

