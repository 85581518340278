import * as React from 'react';

const SvgEnvelope = props => (
  <svg
    data-name="Group 1840"
    height={28.192}
    viewBox="0 0 42.26 28.192"
    width={42.26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      data-name="Group 1839"
    >
      <path
        d="M0 26.395V1.8A2.312 2.312 0 0 1 2.677 0h37.152a2.159 2.159 0 0 1 1.732.611 7.023 7.023 0 0 1 .7 1.187v24.6a2.316 2.316 0 0 1-2.679 1.8H2.389A2.1 2.1 0 0 1 .7 27.58a7.2 7.2 0 0 1-.7-1.185M40.608 2.682c-.187.136-.31.223-.43.313l-16.6 12.449a3.842 3.842 0 0 1-4.875.011L2.036 2.959c-.114-.085-.231-.165-.37-.263v22.631c.155-.184.261-.305.363-.431l7.839-9.685c.3-.374.595-.758.917-1.114a.794.794 0 0 1 1.114-.075.81.81 0 0 1 .139 1.081 3 3 0 0 1-.2.26L3.114 26.138c-.1.121-.189.247-.3.389h36.629a7.729 7.729 0 0 0-.25-.333l-7.166-8.849c-.589-.727-1.184-1.448-1.761-2.184a.791.791 0 0 1 .1-1.143.814.814 0 0 1 1.146.111c.066.07.122.149.183.224l8.595 10.612c.086.106.179.207.318.365ZM3.024 1.645l.259.215q8.246 6.187 16.494 12.372a2.206 2.206 0 0 0 2.751-.035q8.2-6.146 16.393-12.3c.087-.065.164-.142.292-.255Z"
        data-name="Path 4075"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgEnvelope;

