exports.components = {
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/NotFoundPage.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/PostPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/PostsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-produkti-page-js": () => import("./../../../src/templates/ProduktiPage.js" /* webpackChunkName: "component---src-templates-produkti-page-js" */),
  "component---src-templates-reprints-page-js": () => import("./../../../src/templates/ReprintsPage.js" /* webpackChunkName: "component---src-templates-reprints-page-js" */)
}

