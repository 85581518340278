import * as React from 'react';

const SvgHand = props => (
  <svg
    data-name="Component 2 \u2013 1"
    height={42.332}
    id="Component_2_1"
    viewBox="0 0 34.853 42.332"
    width={34.853}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      data-name="Group 1817"
      id="Group_1817"
    >
      <path
        d="M68.133,17.08v-.574q0-6.282,0-12.563a3.757,3.757,0,1,1,7.51-.156c.021,2.672.005,5.345.005,8.018v.511a3.856,3.856,0,0,1,4.423,1.5,3.861,3.861,0,0,1,5.19,1.47,3.911,3.911,0,0,1,2.448-.315,3.617,3.617,0,0,1,2.984,3.413c.05,2.7.108,5.409-.063,8.1a13.088,13.088,0,0,1-4.584,9.1.959.959,0,0,0-.36.8c.016,1.815.008,3.63.008,5.445v.5H68.133v-.453c0-1.872.006-3.745-.009-5.617a.82.82,0,0,0-.216-.552,13.815,13.815,0,0,1-4.786-10.6c-.018-1.615-.013-3.23,0-4.845a3.077,3.077,0,0,1,3.2-3.185c.583,0,1.167,0,1.815,0M83.195,39.857c.009-.146.022-.271.022-.4,0-1.415.015-2.83-.008-4.245a.929.929,0,0,1,.423-.848,10.767,10.767,0,0,0,4.544-8.154c.152-2.549.063-5.114.059-7.672a1.219,1.219,0,0,0-.956-1.134A1.277,1.277,0,0,0,85.7,18.657c-.018.493,0,.987,0,1.473h-2.48c0-1.021.009-2,0-2.989a1.266,1.266,0,1,0-2.532-.038c-.022.757-.007,1.515-.007,2.272,0,.252,0,.5,0,.758H78.227c-.009-.116-.022-.2-.022-.285,0-1.3.01-2.6-.006-3.9a1.276,1.276,0,1,0-2.547.028c-.005.786,0,1.572,0,2.358v1.8H73.17v-.548q0-7.718,0-15.436c0-.143.005-.286,0-.429a1.277,1.277,0,0,0-2.549-.062,4.463,4.463,0,0,0-.008.471q0,9.6,0,19.209V23.9H68.125V19.555c-.6,0-1.142,0-1.683,0-.665.008-.849.188-.85.838,0,1.543-.013,3.087,0,4.631a11.332,11.332,0,0,0,4.732,9.432.661.661,0,0,1,.289.609c-.011,1.443-.005,2.887-.005,4.331v.46Z"
        data-name="Path 4068"
        // fill="#00572c"
        id="Path_4068"
        transform="translate(-55.893 -0.001)"
      />
      <path
        d="M4.784,20.194H9.495v2.459H4.778L6.19,23.984,4.374,25.8,0,21.438l4.393-4.383L6.1,18.768,4.686,20.021l.1.173"
        data-name="Path 4069"
        // fill="#00572c"
        id="Path_4069"
        transform="translate(0 -15.105)"
      />
      <path
        d="M198.315,20.335h4.741l-1.414-1.373,1.741-1.753,4.368,4.371-4.333,4.341-1.705-1.693,1.374-1.305-.036-.1h-4.735Z"
        data-name="Path 4070"
        // fill="#00572c"
        id="Path_4070"
        transform="translate(-175.638 -15.241)"
      />
    </g>
  </svg>
);

export default SvgHand;

