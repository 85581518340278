import * as React from 'react';

const SvgZinc = props => (
  <svg
    data-name="Component 12 \u2013 1"
    height={689}
    id="Component_12_1"
    viewBox="0 0 689 689"
    width={689}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      data-name="Group 1982"
      id="Group_1982"
      transform="translate(-0.118 -0.117)"
    >
      <g
        data-name="Group 1981"
        id="Group_1981"
      >
        <path
          d="M0,344.728C-.043,154.082,154.913-.839,346.224,0,535.364.836,690.14,154.57,689.232,346.565c-.894,189.018-154.9,343.647-346.479,342.657C153.507,688.244.162,534.733,0,344.728m645.842-.127C645.83,178.712,511.466,45.189,348.38,43.4,178.982,41.532,44.565,178.424,43.39,342.077,42.183,510.293,178.185,644.365,341.436,645.869,510.517,647.427,646.082,510.2,645.842,344.6"
          data-name="Path 4121"
          fill="#fff"
          id="Path_4121"
          transform="translate(0 0)"
        />
        <path
          d="M615.073,338.477C617.2,492.3,492.356,615.853,341.1,615.117,191.2,614.387,70.256,493.1,69.4,343.743c-.87-151.927,123.663-277.806,279.39-274.266,145.523,3.308,264.231,121.258,266.281,269M98.4,342.185c.169,134.622,108.848,242.565,241.038,243.909C476.684,587.49,585.85,476.636,586.1,342.964c.25-135.193-108.688-242.85-240.317-244.53C207.969,96.673,98.493,208.066,98.4,342.185"
          data-name="Path 4122"
          fill="#fff"
          id="Path_4122"
          transform="translate(2.358 2.358)"
        />
        <path
          d="M221.647,422.956h12.712q46.63,0,93.257,0c7.4,0,13.037,3.779,15.046,9.858,3.314,10.029-4.427,19.408-14.466,19.213-16.955-.33-33.922-.109-50.883-.111-27.383,0-54.767-.16-82.148.087-9.652.087-16.2-7.793-15.2-15.575a17.883,17.883,0,0,1,2.665-7.377c14.623-22.059,29.4-44.015,44.134-66q36.448-54.4,72.893-108.8c.553-.828,1.1-1.664,1.95-2.953h-3.11q-51.795,0-103.59-.008c-10.242-.007-17.137-8.953-14.492-18.7a14.164,14.164,0,0,1,12.8-10.234c5.42-.154,10.848-.074,16.272-.074q57.736,0,115.474-.007c4.029,0,8.01-.31,11.7,2.047a14.765,14.765,0,0,1,4.3,20.561q-30.2,44.908-60.316,89.873-27.916,41.622-55.815,83.254c-.985,1.471-1.917,2.979-3.18,4.946"
          data-name="Path 4123"
          fill="#fff"
          id="Path_4123"
          transform="translate(6.111 7.553)"
        />
        <path
          d="M389.8,286.117c4.36-2.594,8.427-5.33,12.763-7.533,11.108-5.64,22.921-6.945,35.074-4.643,20.38,3.862,36.018,14.571,46.214,32.813a54.323,54.323,0,0,1,7.124,26.548c.13,34.093.09,68.187.093,102.28a14.162,14.162,0,0,1-11.353,14.372c-7.637,1.471-14.295-2.283-16.683-9.225a20.589,20.589,0,0,1-.943-6.565c-.057-32.285-.328-64.574.081-96.855.213-16.884-11.568-30.784-27.839-34.516-13-2.983-22.988,2.212-31.554,11.258a52.085,52.085,0,0,0-11.754,19.479,22.816,22.816,0,0,0-1.244,7q-.119,47.395-.059,94.788c0,7.076-4.754,13.319-11.074,14.657-6.9,1.46-13.781-1.749-16.489-8.019a17.468,17.468,0,0,1-1.351-6.721q-.115-73.482-.058-146.963c0-8.492,5.326-14.71,13.514-15.311a14.325,14.325,0,0,1,15.265,12.075c.062.333.166.658.274,1.074"
          data-name="Path 4124"
          fill="#fff"
          id="Path_4124"
          transform="translate(12.258 9.272)"
        />
      </g>
    </g>
  </svg>
);

export default SvgZinc;

