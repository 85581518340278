import * as React from 'react';

const SvgGinger = props => (
  <svg
    data-name="Component 13 \u2013 1"
    height={626.257}
    id="Component_13_1"
    viewBox="0 0 913.543 626.257"
    width={913.543}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      data-name="Group 1983"
      id="Group_1983"
    >
      <path
        d="M105,245.214c-11.469-11.013-23.844-18.972-38.444-23.3-1.467-.434-3.348-2.035-3.652-3.4-1.419-6.415-2.35-12.938-3.466-19.419-2.784-16.217,1.58-31.12,9.9-44.607,9.335-15.129,14.27-31.739,19.511-48.413,4.275-13.6,11.505-25.292,26.448-29.315a87.028,87.028,0,0,1,19.508-3.01c13.352-.456,26.8.705,40.076-.393a173.774,173.774,0,0,0,34.013-6.638c3.954-1.152,8.244-5.928,9.689-10.006,4.188-11.82,11.714-13.862,22.954-11.4,23.007,5.041,39.115,19.923,46.354,40.9.827,2.4.061,5.774-.944,8.311-4.451,11.239-9.2,22.366-14.014,33.455a9.013,9.013,0,0,1-3.587,3.9c-12.488,7.183-25.539,12.805-40.082,14.407-14.593,1.608-29.26,2.886-43.7,5.408-26.691,4.661-46.315,19.858-58.519,43.7-7.278,14.222-12.564,29.46-18.814,44.214a56.432,56.432,0,0,1-3.227,5.6"
        data-name="Path 4125"
        fill="#fff"
        id="Path_4125"
        transform="translate(68.563 51.939)"
      />
      <path
        d="M246.234,86.719c-.319,4.9-.668,9.272-.877,13.652-.588,12.384,3.624,23.538,9.259,34.2,2.192,4.143,1.9,6.358-2.433,8.947-21.411,12.8-40.677,28.31-56.978,47.282-19.068,22.193-32.926,47.276-37.29,76.356-2.053,13.68-.6,27.913-.391,41.89.059,3.863-.365,6.358-4.611,7.586-12.135,3.511-24.131,7.5-36.285,10.933a8.243,8.243,0,0,1-6.486-1.41c-16.631-13.832-25.682-31.851-29.8-52.951-2.992-15.333-1.827-30.252,1.174-45.3.786-3.936,2.921-5.722,6.64-6.545,6.517-1.445,8.005-4.01,5.191-9.865-1.84-3.832-4.754-7.135-7.009-10.785-.651-1.057-1.3-2.749-.9-3.722q8.278-20.262,16.9-40.386c12.1-28.132,32.949-45.809,63.113-51.948a125.769,125.769,0,0,1,20.244-2.424c20.248-.764,39.837-3.847,57.636-14.333a23.968,23.968,0,0,1,2.91-1.176"
        data-name="Path 4126"
        fill="#fff"
        id="Path_4126"
        transform="translate(91.962 101.462)"
      />
      <path
        d="M219.685,324.015c-13.337-4.715-25.641-10.436-38.579-13.222-10.989-2.367-14.431-8.556-16.59-18.172-4.012-17.879-2.051-35.365,2.641-52.579,5.759-21.138,14.719-41.015,25.882-59.9,1.636-2.767,3.268-3.448,6.03-2.248,8.235,3.578,16.425,3.4,22.9-2.995,7.235-7.148,8.561-16.2,4.737-25.732-1.189-2.962-2.239-6.323-4.379-8.485-4.475-4.524-3.09-8.192,0-12.608,8.018-11.455,19.439-17.634,32.691-19.573a260.279,260.279,0,0,1,37.044-2.242c12.82-.028,24.157-4.058,35.054-10.153,1.853-1.037,3.737-2.012,6.525-3.507,1.213,5.134,2.606,9.806,3.39,14.578,2.443,14.9.514,29.651-1.632,44.438-1.953,13.461-3.448,27-4.661,40.551A36.316,36.316,0,0,0,332.018,206c1.61,5.093-.794,5.182-4.331,5.113a96.422,96.422,0,0,0-34.236,5.874c-25.6,9.092-43.641,26.713-56.1,50.537-9.257,17.7-14.615,36.6-17.666,56.49"
        data-name="Path 4127"
        fill="#fff"
        id="Path_4127"
        transform="translate(189.955 108.339)"
      />
      <path
        d="M242.761,83.524c-7.209,1.467-13.389,3.565-19.647,3.841-18.363.8-36.767.6-55.134,1.339-12.846.519-25.37,3.817-33.041,14.836-5.375,7.723-9.155,16.9-12.039,25.938-4.511,14.142-9.24,28.013-17.134,40.729C93,190.768,91.574,212.757,98.473,235.6a13.157,13.157,0,0,1,.237,2.322c-4.114,0-8.057-.278-11.948.059-7.656.664-15.268,1.864-22.931,2.407a9.537,9.537,0,0,1-6.408-2.016c-16.149-14.444-25.849-32.648-30.085-53.74a118.034,118.034,0,0,1,1.9-55.3c2.793-10.088,6.623-19.89,10.065-29.8,1.131-3.255,3.257-5.028,6.97-5.7a95.951,95.951,0,0,0,14.793-4.344c7.79-2.76,11.824-9.379,10.086-17.345-1.137-5.208.286-8.732,3.906-12.5,16.115-16.792,35.692-26.452,58.634-29.595,24.8-3.4,48.775.347,72.17,8.6a11.752,11.752,0,0,1,5.008,4.26c7.921,11,15.557,22.208,23.553,33.151,1.845,2.526,4.726,4.292,8.339,7.471"
        data-name="Path 4128"
        fill="#fff"
        id="Path_4128"
        transform="translate(29.174 33.692)"
      />
      <path
        d="M305.417,290.879c1.858.529,3.16.829,4.414,1.269,10.24,3.6,18-1.756,18.1-12.71.113-12.165-4.57-23.011-10.18-33.4-1.751-3.244-1.641-5.262.247-8.222,14.025-21.993,33.305-37.763,56.85-48.567,15.618-7.165,32.134-10.5,49.19-11.894,5.267-.43,5.418,2.632,5.855,6.124,1.4,11.2,3.446,22.293,9.259,32.116,2.185,3.7,6.091,6.336,8.849,9.75,1.068,1.322,2.046,3.839,1.5,5.167a145.482,145.482,0,0,1-40.69,56.531c-15.852,13.478-32.522,26.149-45.395,42.788-12.488,16.145-22.392,33.594-25.029,54.214a79.433,79.433,0,0,0-.093,19.9c.983,7.73,1.048,8.029-6.9,8.556-9.431.625-18.827,1.773-29.6,2.828,7.515-40.976,14.01-82.037,3.624-124.448"
        data-name="Path 4129"
        fill="#fff"
        id="Path_4129"
        transform="translate(353.102 207.458)"
      />
      <path
        d="M375.6,223.048c-57.944,34.575-85.525,83.135-72.585,152.7-4.924-4.843-8.88-8.077-12.039-11.959-11.514-14.151-18.276-15.921-34.063-7.172-5.071,2.81-8.148,2.567-12.564-1.382-6.1-5.462-13.387-9.589-20.038-14.459-18.508-13.552-25.411-33.755-19.235-55.928.716-2.569,1.549-5.119,2.079-7.727.775-3.824,2.686-5.588,6.833-6.182,7.905-1.137,11.887-8.439,8.888-16.87-1.907-5.364-.966-9.461,1.892-13.964,7.532-11.868,15.238-23.579,27.214-31.526,13.91-9.229,29.31-11.629,45.644-9.283,19.027,2.732,37.873,2.255,56.347-3.581,3.255-1.026,5.154-.423,6.933,2.756,4.622,8.253,9.68,16.262,14.693,24.58"
        data-name="Path 4130"
        fill="#fff"
        id="Path_4130"
        transform="translate(237.358 228.449)"
      />
      <path
        d="M502.806,226.473c-10.579,21.166,1,38.676,9.388,56.707-7.934,1.1-15.19,1.795-22.316,3.16-25.669,4.915-46.692,18.172-64.74,36.628a199.231,199.231,0,0,0-41.415,63.36c-2.432,5.831-7.053,7.207-12.013,8.3-11.427,2.519-22.766.846-34.052-1.413-3.492-.7-7.044-1.7-10.542-1.6-4.961.137-5.952-2.509-6.451-6.566-3.433-27.911,7.688-50.922,23.974-72.29,10.807-14.181,24.389-25.415,38.14-36.5,21.182-17.072,37.984-37.546,48.376-62.9a12.856,12.856,0,0,1,7.871-7.788c5.683-2.341,10.6-6.579,15.774-10.1,2.53-1.721,4.594-1.712,6.36,1,9.422,14.47,22.225,24.391,39,28.981.814.223,1.582.605,2.647,1.02"
        data-name="Path 4131"
        fill="#fff"
        id="Path_4131"
        transform="translate(374.451 227.322)"
      />
      <path
        d="M323.2,209.639c5.583,6.966,10.321,13.758,15.963,19.689,6.54,6.875,14.055,12.814,20.754,19.548a17.911,17.911,0,0,1,4.2,8.261c4.616,19.543,6.245,39.358,3.765,59.33-2.331,18.781-5.251,37.491-7.667,56.264-.6,4.652-2.43,6.23-7.228,6.349-14.2.352-28.384,1.871-42.578,1.9-19.122.037-37.2-4.754-53.152-15.6-2.756-1.875-4.661-6.286-5.28-9.822-6.119-34.894-2.552-68.182,17.855-98.265,13.771-20.3,31.984-35.7,53.372-47.649"
        data-name="Path 4132"
        fill="#fff"
        id="Path_4132"
        transform="translate(291.65 245.281)"
      />
      <path
        d="M370.958,245.246c-19.448,6.28-37.426,7.306-56.173,4.839-26.131-3.439-49.761,2.311-66.617,24.773-4.4,5.861-8.381,12.052-12.33,18.235-1.432,2.244-2.587,3.867-4.494.79-8.838-14.246-22.212-23.063-36.628-30.556-3.791-1.97-4.494-3.949-3.785-8.383,3.993-24.92,12-48.207,27.414-68.375,14.687-19.218,33.959-31.624,58.08-35.658a167.528,167.528,0,0,1,21.349-2.049c1.973-.074,4.685,1.513,5.939,3.179q17.278,22.948,34.1,46.234c11.03,15.322,21.783,30.845,33.145,46.97"
        data-name="Path 4133"
        fill="#fff"
        id="Path_4133"
        transform="translate(223.105 174.168)"
      />
      <path
        d="M279.441,149.737c-9.468-40.423-15.383-80.623-3.413-121.979,3.674,5.978,6.586,11.406,10.151,16.366,6.382,8.882,15.114,15.212,24.469,20.591,24.467,14.068,50.718,21.7,79.112,20.68,4.841-.174,3.574,2.832,3.581,4.114,5.16-3.068,10.18-7.354,15.978-9.212,10.342-3.316,19.641,1.48,28.115,6.89A61.9,61.9,0,0,1,465.29,126.86c.527,2.309.078,5.716-1.343,7.45-8.624,10.52-17.182,21.19-26.873,30.677-2.608,2.552-9.171,1.97-13.76,1.443-24.076-2.762-46.1-12.632-68.835-20.142a185.375,185.375,0,0,0-37.99-8.331c-11.091-1.194-21.943,2.32-31.326,9.027q-1.745,1.25-3.559,2.4a9.522,9.522,0,0,1-2.161.352"
        data-name="Path 4134"
        fill="#fff"
        id="Path_4134"
        transform="translate(315.121 32.477)"
      />
      <path
        d="M312,278.653c-10.336-5.842-20.151-11.284-29.788-17.019-.857-.51-1.122-3.1-.727-4.455,4.3-14.789,10.47-29.258,12.836-44.331,3.917-24.934.165-48.947-16.8-69.28-.688-.825-1.282-1.73-2.09-2.825,1.1-.766,2-1.8,3.079-2.066,7.9-1.96,11.1-8.142,14.244-14.773,1.317-2.784,3.717-5.922,6.406-7.063,28.158-11.968,56.661-11.549,85.273-1.5,3.513,1.235,8.324,1.53,10.149,4.012,1.673,2.279.226,6.931-.026,10.525-.419,5.989,2.322,10.229,6.009,14.847,2.823,3.533,3.869,9.045,4.321,13.808,1.649,17.367-.779,33.824-14.678,46.187-8.148,7.246-18.387,10.982-28.783,13.565-29.321,7.289-43.533,27.3-48.2,55.743a43.661,43.661,0,0,1-1.23,4.626"
        data-name="Path 4135"
        fill="#fff"
        id="Path_4135"
        transform="translate(322.264 126.161)"
      />
      <path
        d="M219.037,282.049c-9.207-1.858-17.521-2.921-25.461-5.251-24.141-7.087-47.669-5.069-70.981,3.468-5.442,1.992-5.987,1.562-6.011-3.926-.046-10.39-.506-20.81.08-31.168,1.148-20.287,8.1-38.917,18.185-56.338,18.465-31.891,45.31-55.294,76.309-74.427,2.919-1.8,4.542-1.267,6.041,1.419,6.955,12.445,14.786,24.157,26.175,33.171.948.753.545,4.284-.278,6.03-6.061,12.883-12.864,25.437-18.512,38.492a148.084,148.084,0,0,0-12.256,54.5,74.934,74.934,0,0,0,6.71,34.026"
        data-name="Path 4136"
        fill="#fff"
        id="Path_4136"
        transform="translate(136.153 132.625)"
      />
      <path
        d="M386.357,126.708a50,50,0,0,1,30.486,18.749c10.768,13.91,16.314,29.97,18.237,47.237,1.467,13.161,2.133,26.413,3.181,39.961-49.891,2.558-88.862,22.868-116.074,63.955-7.376-9.424-13.6-18.881-21.351-26.847-7.2-7.389-7.406-14.567-4.787-23.727,3.55-12.41,7.823-24.12,17.7-32.991,8.654-7.773,19.591-10.8,30.187-14.6,6.512-2.341,13.144-4.657,19.176-7.975,15.578-8.569,22.019-22.9,23.161-39.913.512-7.647.087-15.357.087-23.844"
        data-name="Path 4137"
        fill="#fff"
        id="Path_4137"
        transform="translate(344.586 148.25)"
      />
      <path
        d="M234.057,344.891c-2.86-1.311-5.384-2.339-7.788-3.6-6.8-3.559-13.385-3.307-20.478-.293a87.832,87.832,0,0,1-18.4,5.31c-6.9,1.267-15.741-5.217-16.818-12.818-1.888-13.32-9.175-23.64-16.408-34.347-6.137-9.084-12.033-18.634-16.06-28.761-6.033-15.175-1.7-29.783,6.078-43.491.089-.154.226-.282.321-.434,10-15.908,10.01-16.062,28.7-14.27,16.028,1.539,31.806,4.4,46.757,10.735a15.381,15.381,0,0,1,1.467.618c4.056,2.25,9.648,3.641,11.607,7.092,1.3,2.289-2.376,7.636-4.2,11.434-9.529,19.817-11.141,40.673-6.634,61.75,2.806,13.12,7.829,25.765,11.835,38.631a12.211,12.211,0,0,1,.017,2.443"
        data-name="Path 4138"
        fill="#fff"
        id="Path_4138"
        transform="translate(158.27 247.369)"
      />
      <path
        d="M350.891,337.162c1.048-2.328,2.053-4.679,3.153-6.981,10.123-21.158,22.783-40.653,39.631-57.043,19.7-19.163,42.723-31.652,70.573-35.406,10.746-1.45,17.078.514,21.763,10.431,5.605,11.872,13.393,22.7,15.906,35.985,4.681,24.721-5.839,40.592-32.75,43.06-19.211,1.762-37.91-2.163-56.859-3.2-16.824-.918-33.216,1.2-48.554,8.86-3.891,1.942-7.927,3.589-11.9,5.371-.323-.358-.644-.718-.968-1.078m91.46-14.786c9.932,0,17.861.612,25.641-.2a54.185,54.185,0,0,0,17.883-4.815c11.1-5.41,15.974-27.685,8.776-38.186-.846-1.235-3.426-1.92-5.108-1.777-2.031.174-4.067,1.324-5.92,2.367-11.8,6.653-22.677,14.58-31.012,25.352-3.793,4.9-6.425,10.7-10.26,17.254"
        data-name="Path 4139"
        fill="#fff"
        id="Path_4139"
        transform="translate(410.547 277.652)"
      />
      <path
        d="M198.282,161.063c-6.026-3.292-11.584-6.1-16.872-9.348-1.623-1-3.368-3.081-3.6-4.856-2.367-18.048,2.485-33.188,17.959-44.177,3.828-2.719,7.211-2.031,11.067-1.035,8.064,2.083,12.38-.948,11.876-9.062-.334-5.412,1.6-8.411,6.067-11.223,16.336-10.292,34.028-15.461,53.17-13.118A73.437,73.437,0,0,1,317.173,85.3a7.674,7.674,0,0,1,2.383,5.477c-.406,7.513-1.135,15.025-2.183,22.475a8.3,8.3,0,0,1-3.353,5.208c-12.669,8.057-26.077,13.808-41.582,13.626-13.417-.158-26.847-1.013-40.117,2.912-14.331,4.238-26.279,11.037-33.147,24.918a6.55,6.55,0,0,1-.892,1.15"
        data-name="Path 4140"
        fill="#fff"
        id="Path_4140"
        transform="translate(207.373 79.192)"
      />
      <path
        d="M290.91,11.1c7.886,14.947,21.822,19.135,36.285,21,12.028,1.549,24.343,1.445,36.508,1.152a95.5,95.5,0,0,0,21.626-3.468c4.216-1.1,5.31.2,6.53,3.683,6.009,17.18,5.03,34.508,1.491,51.926-.814,4-1.907,7.951-2.617,11.968-.582,3.294-2.376,4.344-5.59,4.414-23.1.493-44.88-4.9-65.843-14.29-11.685-5.234-22.479-11.744-31.5-20.9-6.9-7.009-12.1-15.038-13.484-25.04-.18-1.3-.38-2.908.193-3.956,4.587-8.422,9.342-16.753,14.114-25.072.3-.527,1.157-.738,2.285-1.413"
        data-name="Path 4141"
        fill="#fff"
        id="Path_4141"
        transform="translate(320.739 12.986)"
      />
      <path
        d="M277.474,331.462c-15.826,8.917-32.279,14.5-50.214,13.133-9.667-.738-19.287-3.047-28.7-5.527-2.862-.755-6.169-4.019-7.181-6.875-5.037-14.216-10.839-28.4-13.621-43.122-4.325-22.874-1.562-45.279,11.6-65.441.386-.592.877-1.118,1.634-2.068,13.228,9.8,27.524,17.089,45.123,19.172-1.267,5.134-2.619,10.123-3.728,15.166-5.345,24.341,3.515,45.692,24.393,59.426,6.41,4.216,12.6,8.776,18.823,13.27.766.553,1.115,1.68,1.868,2.867"
        data-name="Path 4142"
        fill="#fff"
        id="Path_4142"
        transform="translate(205.762 259.231)"
      />
      <path
        d="M64.593,60.152a64.728,64.728,0,0,1-1.936,6.764c-7.775,18.491-12.152,37.8-12.109,57.8.065,30.2,9.665,56.854,31.745,78.3.23.224.267.644.616,1.545-3.867.7-7.593,1.651-11.377,2-16.492,1.534-32.923,2.932-47.35-8.172-11.59-8.923-18.517-20.813-21.8-34.64-6.269-26.426,0-50.214,16.483-71.6C30.539,77,46.315,67.879,64.593,60.152"
        data-name="Path 4143"
        fill="#fff"
        id="Path_4143"
        transform="translate(0 70.379)"
      />
      <path
        d="M396.46,148.282c-33.36-14.454-67.507-16.633-102.742-1.113-.686-4.216-.716-7.994-1.942-11.334-4.833-13.146-9.871-26.227-15.233-39.167-1.33-3.214-1.423-4.919,1.573-7.037,12.143-8.589,25.526-10.553,39.974-8.639,20.776,2.752,39.477,11.907,59,18.53,13.9,4.715,28.01,8.454,42.79,9.064a16.341,16.341,0,0,1,3.633.979c-9.088,13.007-17.844,25.537-27.056,38.717"
        data-name="Path 4144"
        fill="#fff"
        id="Path_4144"
        transform="translate(322.524 93.976)"
      />
      <path
        d="M180.263,211.21c-2.532,4.038-4.342,7-6.226,9.915-6.59,10.2-11.306,21.1-11.764,33.412-.506,13.573,4.477,25.53,11.8,36.571,5.132,7.734,10.778,15.123,16.086,22.742a73.682,73.682,0,0,1,3.739,6.981c-13.565,4.388-26.528,8.624-39.544,12.7-.977.306-2.467-.3-3.463-.9-15.83-9.583-29.091-21.78-36.543-39.026-8.459-19.582-5.618-38.819,3.7-57.367a87.526,87.526,0,0,1,9.316-14.99c2-2.532,5.425-5,8.5-5.44,11.338-1.623,22.8-2.38,34.21-3.481,2.819-.271,5.629-.612,10.182-1.111"
        data-name="Path 4145"
        fill="#fff"
        id="Path_4145"
        transform="translate(127.898 247.119)"
      />
      <path
        d="M322.6,175.73c.061,23.367-5.69,42.556-13.71,61.171-.584,1.354-3.463,3.121-4.414,2.708-21.524-9.316-40.147-22.2-50.116-44.412-13.829-30.812-.3-58.046,32.787-68.668,2.537-.814,5.228-1.226,7.66-2.263,5.991-2.552,9.34.781,12.378,5.126,10.329,14.765,16,30.977,15.416,46.339"
        data-name="Path 4146"
        fill="#fff"
        id="Path_4146"
        transform="translate(291.315 144.354)"
      />
      <path
        d="M201.889,116.705c-3.335-5.379-7.113-10.321-9.507-15.858-1.05-2.428-.308-6.946,1.421-8.995,3.869-4.581,4.8-9.828,5.683-15.249,3.025-18.571,13.938-30.775,31.318-36.649,17.829-6.03,34.232-2.3,48.25,10.453,12.764,11.618,18.426,26.845,21.3,43.348.92,5.277,1.562,10.611,2.1,15.941.221,2.2-.154,4.466-.306,7.743-33.932-23.929-67.175-23.3-100.263-.733"
        data-name="Path 4147"
        fill="#fff"
        id="Path_4147"
        transform="translate(224.523 43.45)"
      />
      <path
        d="M105.8,149.574c-8.248,40.547-2.8,76.6,28.39,105.573-.145.519-.293,1.037-.438,1.556-6.662.213-13.343.868-19.977.547-15.819-.764-30.243-5.8-41.781-16.887-26.424-25.4-22.1-69.011,16.755-84.932,5.058-2.072,10.351-3.574,17.052-5.857"
        data-name="Path 4148"
        fill="#fff"
        id="Path_4148"
        transform="translate(64.646 175.004)"
      />
      <path
        d="M180.721,54.568C131.32,40.4,85.661,40.1,45.826,77.648c.5-5.323.553-10.737,1.588-15.956C50.83,44.471,58.16,29.656,73.936,20.2c12.219-7.322,25.222-10.017,39.455-8.767a83.075,83.075,0,0,1,43.361,16.531c9.212,6.888,16.483,15.683,23.968,26.6"
        data-name="Path 4149"
        fill="#fff"
        id="Path_4149"
        transform="translate(53.617 13.037)"
      />
      <path
        d="M298.095,25.2c-10.49,33.067-8.977,65.877-4.116,99.417-17.245-12.491-34.766-23.165-42.623-43.854-10.329-27.192,8.6-50.288,33.857-54.059,4.158-.621,8.355-.981,12.883-1.5"
        data-name="Path 4150"
        fill="#fff"
        id="Path_4150"
        transform="translate(290.723 29.484)"
      />
      <path
        d="M340.427,43.678c-17.184-1.647-31.7-2.207-44.913-9.151-5.2-2.732-9.793-6.2-11.605-12.026-.5-1.617-.065-4.418,1.065-5.51C295.454,6.879,307.983.293,322.6.011A51.943,51.943,0,0,1,360.354,15.1c5.594,5.412,9.93,12.148,14.656,18.421,2.029,2.693,2.161,4.8-2.246,5.392-11.686,1.558-23.33,3.42-32.338,4.763"
        data-name="Path 4151"
        fill="#fff"
        id="Path_4151"
        transform="translate(331.936 0)"
      />
      <path
        d="M138.249,215.282c-29.193,45.232-21.071,82.636,21.724,113.437-20.843,2.578-52.849-10.464-61.711-43.2-8.359-30.868,11.557-59.86,39.987-70.237"
        data-name="Path 4152"
        fill="#fff"
        id="Path_4152"
        transform="translate(112.719 251.883)"
      />
      <path
        d="M242.366,175.248c3.385-26.678,10.112-53.636,1.5-80.952,4.4-4.425,9.648-1.712,14.359-1.23,18.419,1.888,26.264,13.6,26.236,28.108-.022,11.3-5.128,21.234-13.341,28.961-9.225,8.68-19.066,16.7-28.755,25.114"
        data-name="Path 4153"
        fill="#fff"
        id="Path_4153"
        transform="translate(283.572 107.409)"
      />
      <path
        d="M137.327,53.308c6.354-12.226,26.11-21.765,38.34-19.1a32.706,32.706,0,0,1,25.795,30.91,144.765,144.765,0,0,1-1.84,28.34c-7.226-11.325-14.068-22.132-25.367-29.386C163.085,56.9,151.02,53.472,137.327,53.308"
        data-name="Path 4154"
        fill="#fff"
        id="Path_4154"
        transform="translate(160.674 39.493)"
      />
      <path
        d="M145.659,220.1c7.682-5.675,14.737-12.621,23.169-16.781,22.423-11.063,43.752,2.791,47.94,25.318.477,2.574.9,5.154,1.621,9.249-23.356-11.955-47.361-15.546-72.081-15.8q-.325-.993-.649-1.986"
        data-name="Path 4155"
        fill="#fff"
        id="Path_4155"
        transform="translate(170.423 233.342)"
      />
      <path
        d="M385.454,196.292c14.237-6.074,27.87-8.146,41.695-2.493,10.182,4.162,13.385,14.272,8.417,24.131-4.783,9.492-6.8,10.193-16.633,6.549-14.2-5.267-24.415-15.166-33.479-28.186"
        data-name="Path 4156"
        fill="#fff"
        id="Path_4156"
        transform="translate(450.987 222.87)"
      />
      <path
        d="M391.808,103.63c-8.775-28.683-27.21-44.943-56.455-49.439,17.8-11.262,37.689-10.972,50.939,1.263,11.625,10.735,14.083,29.362,5.516,48.176"
        data-name="Path 4157"
        fill="#fff"
        id="Path_4157"
        transform="translate(392.368 53.822)"
      />
      <path
        d="M185.583,132.157C170.54,123.842,163.811,108.044,169.325,96,175.3,82.954,190.014,77.369,207.7,82.52c-17.189,12.226-26.021,27.859-22.121,49.637"
        data-name="Path 4158"
        fill="#fff"
        id="Path_4158"
        transform="translate(195.878 94.325)"
      />
    </g>
  </svg>
);

export default SvgGinger;

